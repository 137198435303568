import style from '../../styles/crmService.module.css'
import openWhatsApp from '../../utils/openWhatsApp';


function CrmService () {
    return (
        <div className="container px-4 py-5" id="icon-grid">
        <h2 className={`pb-2 border-bottom ${style.title}`}>SALESFORCE <span className={style.span}>CRM</span></h2>
    
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-calendar-check text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Implementación de Salesforce CRM de Vanguardia</h3>
            </div>
          </div>
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-cloud-fill text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Brinda un Servicio al Cliente Excepcional con Salesforce Service Cloud</h3>
            </div>
          </div>
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-receipt-cutoff text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Optimiza tus Ventas con Salesforce Sales Cloud</h3>
            </div>
          </div>
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-graph-up-arrow text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Haz Crecer tu Negocio con Salesforce Marketing Cloud</h3>
            </div>
          </div>
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-award-fill text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Crea Experiencias de Compra Inolvidables con Salesforce Commerce Cloud</h3>
            </div>
          </div>
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-pencil-fill text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Desarrolla Aplicaciones Personalizadas con Salesforce Platform</h3>
            </div>
          </div>
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-motherboard-fill text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Mantén tu Éxito con Nuestro Soporte y Mantenimiento Continuo</h3>
            </div>
          </div>
          <div onClick={openWhatsApp} className={`col d-flex align-items-start ${style.cardContainer}`} data-aos="zoom-in">
            <i className={`bi bi-reception-3 text-body-secondary me-3 ${style.icon}`}></i>
            <div>
              <h3 className={`fw-bold mb-0 fs-4 text-body-emphasis ${style.cardTitle}`}>Obtén Asesoramiento Estratégico Personalizado</h3>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CrmService;