import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import style from '../../styles/serviceGroup.module.css'
import Image from "react-bootstrap/Image";
import art1 from '../../assets/svg/ServiceSvgs/Developer activity-bro.svg'
import art2 from '../../assets/svg/ServiceSvgs/Business analytics-rafiki green.svg'
import art3 from '../../assets/svg/ServiceSvgs/Data points-cuate.svg'
import art4 from '../../assets/svg/ServiceSvgs/Pair programming-bro.svg'
import openWhatsApp from '../../utils/openWhatsApp';

function ServicesGroup() {
  return (
    <Container fluid className='px-0'>
      <Row>
        <Col xs={12} md={6} lg={3} className='mt-5' data-aos="fade-down">
          <Container className={`px-0 ${style.card}`} onClick={openWhatsApp} fluid>
            <h4 className={style.title}>DESARROLLO DE SOFTWARE</h4>
            <Image src={art1} className={style.image} fluid></Image>
            <h6 className={style.subtitle}>Crear tu aplicación web</h6>
            <p className={style.cardtext}>Desarrollo impulsado por pruebas (TDD) con seguridad y cumplimiento de normativas.</p>
          </Container>
        </Col>
        <Col xs={12} md={6} lg={3} className='mt-5' data-aos="fade-up">
          <Container className={`px-0 ${style.card}`} onClick={openWhatsApp} fluid>
            <h4 className={style.title}>SALESFORCE (CRM)</h4>
            <Image src={art2} className={style.image} fluid></Image>
            <h6 className={style.subtitle}>Optimiza tus Ventas con Salesforce Sales Cloud</h6>
            <p className={style.cardtext}>Brinda un Servicio al Cliente Excepcional con Salesforce Service Cloud</p>
          </Container>
        </Col>
        <Col xs={12} md={6} lg={3} className='mt-5' data-aos="fade-down">
          <Container className={`px-0 ${style.card}`} onClick={openWhatsApp} fluid>
            <h4 className={style.title}>CIENCIA DE DATOS</h4>
            <Image src={art3} className={style.image} fluid></Image>
            <h6 className={style.subtitle}>Diseño de bases de datos optimizadas</h6>
            <p className={style.cardtext}>Desarrollo de modelos de datos para análisis predictivo</p>
          </Container>
        </Col>
        <Col xs={12} md={6} lg={3} className='mt-5' data-aos="fade-up">
          <Container className={`px-0 ${style.card}`} onClick={openWhatsApp} fluid>
            <h4 className={style.title}>CONSULTORÍA TECNOLÓGICA</h4>
            <Image src={art4} className={style.image} fluid></Image>
            <h6 className={style.subtitle}>Capacitaciones y Evaluaciones de infraestructura</h6>
            <p className={style.cardtext}>Asesoramiento en estrategias de transformación digital</p>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ServicesGroup;