// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/background1(Azul).svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Services_services__9HEKD {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    padding-top: 100px !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.Services_colCard__WgWWx {
    margin: 0px 10px;
}

.Services_title__SEIvj {
    font-family: ITC Avant Garde Std;
    font-weight: bold;
}
.Services_subtitle__aa5\\+j {
    font-family: ITC Avant Garde Std;
    font-weight: 400;
}

.Services_span__u3kFT {
    color: #00567d;
}

.Services_cardtext__iH8Vb {
    color: antiquewhite;
    font-size: 100px !important;
}


`, "",{"version":3,"sources":["webpack://./src/styles/Services.module.css"],"names":[],"mappings":"AAAA;IACI,yDAA4D;IAC5D,6BAA6B;IAC7B,4BAA4B;IAC5B,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;AACrB;AACA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":[".services {\n    background-image: url('../assets/img/background1(Azul).svg');\n    padding-top: 100px !important;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position-x: center;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n}\n\n.colCard {\n    margin: 0px 10px;\n}\n\n.title {\n    font-family: ITC Avant Garde Std;\n    font-weight: bold;\n}\n.subtitle {\n    font-family: ITC Avant Garde Std;\n    font-weight: 400;\n}\n\n.span {\n    color: #00567d;\n}\n\n.cardtext {\n    color: antiquewhite;\n    font-size: 100px !important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services": `Services_services__9HEKD`,
	"colCard": `Services_colCard__WgWWx`,
	"title": `Services_title__SEIvj`,
	"subtitle": `Services_subtitle__aa5+j`,
	"span": `Services_span__u3kFT`,
	"cardtext": `Services_cardtext__iH8Vb`
};
export default ___CSS_LOADER_EXPORT___;
