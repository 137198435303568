// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUs_title__j4Pi0 {
    font-family: ITC Avant Garde Std;
    font-weight: bold;
}

.AboutUs_subtitle__XeTjl {
    font-family: ITC Avant Garde Std;
    font-weight: 500;
}

.AboutUs_parraf__kLQes {
    font-family: Arial;
    font-weight: 600;
    font-size: 30px;
}

.AboutUs_span__2eaiz {
    color:  #00567d;
    font-weight: bold;
}


@media (max-width: 996px) {
    .AboutUs_parraf__kLQes {
        font-size: 20px;

    }
}








`, "",{"version":3,"sources":["webpack://./src/styles/AboutUs.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;;AAGA;IACI;QACI,eAAe;;IAEnB;AACJ","sourcesContent":[".title {\n    font-family: ITC Avant Garde Std;\n    font-weight: bold;\n}\n\n.subtitle {\n    font-family: ITC Avant Garde Std;\n    font-weight: 500;\n}\n\n.parraf {\n    font-family: Arial;\n    font-weight: 600;\n    font-size: 30px;\n}\n\n.span {\n    color:  #00567d;\n    font-weight: bold;\n}\n\n\n@media (max-width: 996px) {\n    .parraf {\n        font-size: 20px;\n\n    }\n}\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `AboutUs_title__j4Pi0`,
	"subtitle": `AboutUs_subtitle__XeTjl`,
	"parraf": `AboutUs_parraf__kLQes`,
	"span": `AboutUs_span__2eaiz`
};
export default ___CSS_LOADER_EXPORT___;
