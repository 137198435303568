// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: white;
    margin: 0;
    padding: 0;
}

.PoliticasPage_privacyContainer__xG998 {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.PoliticasPage_heading1__FQ9SW {
    color: #cfdb00;
    margin-bottom: 15px;
    font-family: ITC Avant Garde Std;
    font-weight: bold;
    font-style: normal;
}

.PoliticasPage_heading2__xIOs4 {
    color: #cfdb00;
    margin-bottom: 15px;
    font-family: Arial;
}

.PoliticasPage_heading3__\\+1X4F {
    color: #888;
    margin-bottom: 10px;
    font-family: Arial;
}

.PoliticasPage_parraf__HNKzS {
    margin-bottom: 20px;
    font-family: Arial;
}

.PoliticasPage_unorderList__RYk37 {
    margin-bottom: 20px;
    list-style-type: disc;
    padding-left: 20px;
    font-family: Arial;
}


.PoliticasPage_amazingLink__BZfNU {
    color: #cfdb00;
    text-decoration: none;
    font-family: Arial;
}

.PoliticasPage_amazingLink__BZfNU:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PoliticasPage.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,gCAAgC;IAChC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,cAAc;IACd,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n    color: #333;\n    background-color: white;\n    margin: 0;\n    padding: 0;\n}\n\n.privacyContainer {\n    max-width: 800px;\n    margin: 30px auto;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.heading1 {\n    color: #cfdb00;\n    margin-bottom: 15px;\n    font-family: ITC Avant Garde Std;\n    font-weight: bold;\n    font-style: normal;\n}\n\n.heading2 {\n    color: #cfdb00;\n    margin-bottom: 15px;\n    font-family: Arial;\n}\n\n.heading3 {\n    color: #888;\n    margin-bottom: 10px;\n    font-family: Arial;\n}\n\n.parraf {\n    margin-bottom: 20px;\n    font-family: Arial;\n}\n\n.unorderList {\n    margin-bottom: 20px;\n    list-style-type: disc;\n    padding-left: 20px;\n    font-family: Arial;\n}\n\n\n.amazingLink {\n    color: #cfdb00;\n    text-decoration: none;\n    font-family: Arial;\n}\n\n.amazingLink:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyContainer": `PoliticasPage_privacyContainer__xG998`,
	"heading1": `PoliticasPage_heading1__FQ9SW`,
	"heading2": `PoliticasPage_heading2__xIOs4`,
	"heading3": `PoliticasPage_heading3__+1X4F`,
	"parraf": `PoliticasPage_parraf__HNKzS`,
	"unorderList": `PoliticasPage_unorderList__RYk37`,
	"amazingLink": `PoliticasPage_amazingLink__BZfNU`
};
export default ___CSS_LOADER_EXPORT___;
