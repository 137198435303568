// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__u5gg\\+ {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    /* height: 10vh; */

}

@media screen and (max-width: 768px) {
    .Footer_footer__u5gg\\+ {
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;;AAEtB;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".footer {\n\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    background-color: white;\n    /* height: 10vh; */\n\n}\n\n@media screen and (max-width: 768px) {\n    .footer {\n        display: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__u5gg+`
};
export default ___CSS_LOADER_EXPORT___;
