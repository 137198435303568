import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react';
import style from '../../styles/Presentation.module.css'
import Image from 'react-bootstrap/Image';
import art from '../../assets/svg/Live collaboration-cuate.svg'
import BtnContact from '../BtnContact';

const words = ['DESARROLLO DE SOFTWARE', 'SALESFORCE (CRM)', 'CIENCIA DE DATOS', 'CONSULTORÍA TECNOLÓGICA'];

const colors = [
    'linear-gradient(to right, #cfdb00, #E7EC9E)',
    'linear-gradient(to right, #337790, #6699AC)',
    'linear-gradient(to right, #cfdb00, #E7EC9E)',
    'linear-gradient(to right, #337790, #6699AC)',
];

const wordColors = [
    '#000000',
    '#FFFFFF',
    '#000000',
    '#FFFFFF',
]

function Presentation () {

    const [index, setIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 2000);
  
      return () => clearInterval(interval);
    }, []);

    return (
        <Container fluid className={style.home} data-aos="zoom-out">
            <div className={style.rowFixed}>
                <div className={`${style.colFixed} ${style.col1}`}>
                    <h1 className={`mt-4 ms-xl-5 ms-0 ${style.title}`}>Impulsa el Crecimiento de tu Empresa <br></br> con Nuestras Soluciones de<br></br> Alta Tecnologia.</h1>
                    <Image src={art} className={`d-md-none d-inline ${style.image2}`} fluid />   
                    <h3 className={`mt-5 ms-xl-5 ms-0 ${style.subtitle}`}>Nos especializamos en proporcionar <br></br>soluciones integrales de tecnología como <br></br>
                        <span
                            id="highlight"
                            style={{
                                backgroundImage: colors[index],
                                color: wordColors[index]
                            }}
                            className={style.word}>
                            {words[index]}
                        </span>
                    </h3>
                    <div className={`ms-xl-5 mt-md-5 mt-3 ${style.buttonContainer}`}>
                        <BtnContact></BtnContact>
                    </div>
                </div>
                <div className={`${style.colFixed} ${style.col2}`}>
                    <Image src={art} className={`d-none d-md-inline ${style.image}`} fluid/>   
                </div>
            </div>
        </Container>
    )

}

export default Presentation;