// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/background3.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_about__rLCow {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px;
    height: 100vh;
}

.About_image__h3z0n {
    width: 70%;
}

.About_title__rDDoF {
    font-family: ITC Avant Garde Std;
    font-weight: bold;
}

.About_subtitle__1RNC4 {
    font-family: ITC Avant Garde Std;
    font-weight: 500;
}

.About_parraf__BoqZ4 {
    font-family: Arial;
    font-weight: 400;
    font-size: 20px;
}

.About_span__k1YRD {
    color: #00567d;
}

@media(max-width: 768px){

    .About_about__rLCow {
        height: 130vh !important;
    }

    .About_title__rDDoF {
        font-family: ITC Avant Garde Std;
        font-weight: bold;
        text-align: center;
    }
    
    .About_subtitle__1RNC4 {
        font-family: ITC Avant Garde Std;
        font-weight: 500;
        text-align: center;

    }
    
    .About_parraf__BoqZ4 {
        font-family: Arial;
        font-weight: 400;
        font-size: 15px;
        text-align: center;
    }

}

@media(max-width: 992px) {

    .About_about__rLCow {
        height: 140vh;
    }

}




`, "",{"version":3,"sources":["webpack://./src/styles/About.module.css"],"names":[],"mappings":"AAAA;IACI,yDAAsD;IACtD,4BAA4B;IAC5B,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI;QACI,wBAAwB;IAC5B;;IAEA;QACI,gCAAgC;QAChC,iBAAiB;QACjB,kBAAkB;IACtB;;IAEA;QACI,gCAAgC;QAChC,gBAAgB;QAChB,kBAAkB;;IAEtB;;IAEA;QACI,kBAAkB;QAClB,gBAAgB;QAChB,eAAe;QACf,kBAAkB;IACtB;;AAEJ;;AAEA;;IAEI;QACI,aAAa;IACjB;;AAEJ","sourcesContent":[".about {\n    background-image: url('../assets/img/background3.svg');\n    background-repeat: no-repeat;\n    background-size: cover;\n    padding-top: 20px;\n    height: 100vh;\n}\n\n.image {\n    width: 70%;\n}\n\n.title {\n    font-family: ITC Avant Garde Std;\n    font-weight: bold;\n}\n\n.subtitle {\n    font-family: ITC Avant Garde Std;\n    font-weight: 500;\n}\n\n.parraf {\n    font-family: Arial;\n    font-weight: 400;\n    font-size: 20px;\n}\n\n.span {\n    color: #00567d;\n}\n\n@media(max-width: 768px){\n\n    .about {\n        height: 130vh !important;\n    }\n\n    .title {\n        font-family: ITC Avant Garde Std;\n        font-weight: bold;\n        text-align: center;\n    }\n    \n    .subtitle {\n        font-family: ITC Avant Garde Std;\n        font-weight: 500;\n        text-align: center;\n\n    }\n    \n    .parraf {\n        font-family: Arial;\n        font-weight: 400;\n        font-size: 15px;\n        text-align: center;\n    }\n\n}\n\n@media(max-width: 992px) {\n\n    .about {\n        height: 140vh;\n    }\n\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `About_about__rLCow`,
	"image": `About_image__h3z0n`,
	"title": `About_title__rDDoF`,
	"subtitle": `About_subtitle__1RNC4`,
	"parraf": `About_parraf__BoqZ4`,
	"span": `About_span__k1YRD`
};
export default ___CSS_LOADER_EXPORT___;
