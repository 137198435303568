import React, { useEffect, useState } from 'react'
import style from '../styles/btnContact.module.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Image from 'react-bootstrap/Image';
import openWhatsApp from '../utils/openWhatsApp'
import generateQR from '../utils/generateQR'

function Boton ({mensaje}) {

    const [qrCodeData, setQrCodeData] = useState(null);

    useEffect(() => {
      const fetchQR = async () => {
        const qrCode = await generateQR(process.env.REACT_APP_TELEFONO);
        setQrCodeData(qrCode);
      };
  
      fetchQR();
    }, []);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          <Image src={qrCodeData} className={style.QR} fluid></Image>
        </Tooltip>
      );
    
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 350}}
          overlay={renderTooltip}
        >
          <button className={style.actionBtn} onClick={openWhatsApp} data-aos="flip-left" data-aos-duration="600" data-aos-anchor-placement="top-bottom">{mensaje ? mensaje : "Contáctanos"}</button>
        </OverlayTrigger>
      );
}

export default Boton;