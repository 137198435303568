// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceGroup_card__fpbty {
    background-color: transparent;
    border-radius: 20px;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 450px;
    padding-top: 10px;
    transition: 0.5s;
}

.serviceGroup_card__fpbty:hover {
    background-color: #CDDDE3;
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.serviceGroup_title__d-3Hi {
    font-family: ITC Avant Garde Std;
    font-weight: bold;
    margin-bottom: 0px;
}

.serviceGroup_subtitle__\\+atjy {
    font-family: ITC Avant Garde Std;
    font-weight: 700;
}

.serviceGroup_text__dHiCG {
    font-family: ITC Avant Garde Std;
    font-weight: 400;

}

.serviceGroup_image__Rgphv {
    height: 300px !important;
}





`, "",{"version":3,"sources":["webpack://./src/styles/serviceGroup.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,wCAAwC;AAC5C;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;;AAEpB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".card {\n    background-color: transparent;\n    border-radius: 20px;\n    align-items: center;\n    text-align: center;\n    width: 100%;\n    height: 450px;\n    padding-top: 10px;\n    transition: 0.5s;\n}\n\n.card:hover {\n    background-color: #CDDDE3;\n    transform: scale(1.05); \n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.title {\n    font-family: ITC Avant Garde Std;\n    font-weight: bold;\n    margin-bottom: 0px;\n}\n\n.subtitle {\n    font-family: ITC Avant Garde Std;\n    font-weight: 700;\n}\n\n.text {\n    font-family: ITC Avant Garde Std;\n    font-weight: 400;\n\n}\n\n.image {\n    height: 300px !important;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `serviceGroup_card__fpbty`,
	"title": `serviceGroup_title__d-3Hi`,
	"subtitle": `serviceGroup_subtitle__+atjy`,
	"text": `serviceGroup_text__dHiCG`,
	"image": `serviceGroup_image__Rgphv`
};
export default ___CSS_LOADER_EXPORT___;
