import Presentation from '../components/home/Presentation'
import Services from '../components/home/Services'
import Space from '../components/home/space'
import About from '../components/home/About'
import Contact from '../components/Contact'

function HomePage () {
    return ( 
        <>
            <Presentation/>
            <Services/>
            <Space altura={90}/>
            <About/>
            <Contact/>
        </>
    )

}

export default HomePage;