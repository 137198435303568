// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html ::-webkit-scrollbar-track{
    /* background: linear-gradient(to bottom, #005575 40%, rgba(0, 159, 219, 0.5) 100%); */
    background-color: white;
}

html ::-webkit-scrollbar-thumb{
    background: #cfdb00;
    border-radius: 5px;
}

html ::-webkit-scrollbar{
    width: 10px;
}

html ::-webkit-scrollbar-track:horizontal{
    /* background: linear-gradient(to bottom, #005575 40%, rgba(0, 159, 219, 0.5) 100%); */
    background-color: transparent;
    
}

html ::-webkit-scrollbar-thumb:horizontal{
    background: transparent;
}

html ::-webkit-scrollbar:horizontal{
    width: 5px;
    
}
`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAAA;IACI,sFAAsF;IACtF,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,sFAAsF;IACtF,6BAA6B;;AAEjC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,UAAU;;AAEd","sourcesContent":["html ::-webkit-scrollbar-track{\n    /* background: linear-gradient(to bottom, #005575 40%, rgba(0, 159, 219, 0.5) 100%); */\n    background-color: white;\n}\n\nhtml ::-webkit-scrollbar-thumb{\n    background: #cfdb00;\n    border-radius: 5px;\n}\n\nhtml ::-webkit-scrollbar{\n    width: 10px;\n}\n\nhtml ::-webkit-scrollbar-track:horizontal{\n    /* background: linear-gradient(to bottom, #005575 40%, rgba(0, 159, 219, 0.5) 100%); */\n    background-color: transparent;\n    \n}\n\nhtml ::-webkit-scrollbar-thumb:horizontal{\n    background: transparent;\n}\n\nhtml ::-webkit-scrollbar:horizontal{\n    width: 5px;\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
