import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import art1 from '../../assets/svg/Server-amico.svg'
import style from '../../styles/AboutUs.module.css'

const About = () => {
  return (
    <Container fluid className={style.about}>
        <Container fluid>
            <Row>
                <Col
                xs={12}
                md={6}
                className="d-flex justify-content-start align-items-top"
                data-aos="fade-up"
                data-aos-duration="500"
                >
                <div className="w-100 p-md-5" style={{height: "100%"}}>
                    <h4 className={`mb-3 mt-5 ${style.subtitle}`}>Impulsamos el crecimiento empresarial</h4>
                    <h1 className={`mb-3 mt-2 ${style.title}`}>Empresa de Desarrollo de Software</h1>
                    <h6 className={`mb-3 mt-md-5 mt-1 ${style.parraf}`}><span className={style.span}>DIRTEK</span> es una empresa de Desarrollo de Software con un equipo multidisciplinario capaz no solo de desarrollar webs, implementar CRM, administrar y crear bases de datos que se alineen con tu modelo de negocio, sino de llevar a <span className={style.span}>tu empresa</span> al éxito tecnológico por medio de nuestra experticia y dedicación.</h6>
                </div>
                </Col>
                <Col
                xs={12}
                md={6}
                className="d-none d-md-flex justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-duration="500"
                >
                <img
                    src={art1}
                    alt="Us"
                    className={`img-fluid p-md-5 ${style.image}`}
                />
                </Col>
            </Row>
        </Container>
    </Container>
  );
};

export default About;