// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/bcTexture2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUsPage_section__BYoKp {
    background-size: cover;
}


.AboutUsPage_section__BYoKp{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed,
                linear-gradient(to right, rgba(207, 219, 0, 0.9) 20%, rgba(208, 219, 0, 0.9) 100%);
    background-size: cover;
}


`, "",{"version":3,"sources":["webpack://./src/styles/AboutUsPage.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;;AAGA;IACI;kGAC8F;IAC9F,sBAAsB;AAC1B","sourcesContent":[".section {\n    background-size: cover;\n}\n\n\n.section{\n    background: url('../assets/img/bcTexture2.png') no-repeat center center fixed,\n                linear-gradient(to right, rgba(207, 219, 0, 0.9) 20%, rgba(208, 219, 0, 0.9) 100%);\n    background-size: cover;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `AboutUsPage_section__BYoKp`
};
export default ___CSS_LOADER_EXPORT___;
