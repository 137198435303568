// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnContact_actionBtn__OwgPx {
    border-radius: 10px !important;
    padding: 10px 20px !important;
    background-image: linear-gradient(to right, #cfdb00, #E7EC9E) !important;
    border: none;
    font-family: ITC Avant Garde Std;
    font-weight: bolder;
    font-size: 20px ;
    color: black;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.btnContact_actionBtn__OwgPx:hover {

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease-in-out;

}

.btnContact_QR__rWgJM {
    width: 200px;
}

.btnContact_imageContainer__vSt3q {
    background-color: #cfdb00;
}

`, "",{"version":3,"sources":["webpack://./src/styles/btnContact.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,6BAA6B;IAC7B,wEAAwE;IACxE,YAAY;IACZ,gCAAgC;IAChC,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,yCAAyC;AAC7C;;AAEA;;IAEI,yCAAyC;IACzC,uCAAuC;;AAE3C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".actionBtn {\n    border-radius: 10px !important;\n    padding: 10px 20px !important;\n    background-image: linear-gradient(to right, #cfdb00, #E7EC9E) !important;\n    border: none;\n    font-family: ITC Avant Garde Std;\n    font-weight: bolder;\n    font-size: 20px ;\n    color: black;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.actionBtn:hover {\n\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);\n    transition: box-shadow 0.3s ease-in-out;\n\n}\n\n.QR {\n    width: 200px;\n}\n\n.imageContainer {\n    background-color: #cfdb00;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBtn": `btnContact_actionBtn__OwgPx`,
	"QR": `btnContact_QR__rWgJM`,
	"imageContainer": `btnContact_imageContainer__vSt3q`
};
export default ___CSS_LOADER_EXPORT___;
