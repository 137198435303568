// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataScienceService_title__SXq45 {
    font-family: ITC Avant Garde Std !important;
    font-weight: bold !important;
}

.dataScienceService_subtitle__THdQm {
    font-family: Arial !important;
    font-weight: 500 !important;
}

.dataScienceService_parraf__u8YBb {
    font-family: Arial;
}

.dataScienceService_cardTitle__iwvPs {
    font-family: ITC Avant Garde Std !important;
    font-weight: bold !important;
}

.dataScienceService_iconContainer__Wadf4 {
    background-color: #cfdb00 !important;
}

.dataScienceService_icon__Fn\\+Vc {
    font-size: 20px; 
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/dataScienceService.module.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;IAC7B,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,2CAA2C;IAC3C,4BAA4B;AAChC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,eAAe;IACf,YAAY;AAChB","sourcesContent":[".title {\n    font-family: ITC Avant Garde Std !important;\n    font-weight: bold !important;\n}\n\n.subtitle {\n    font-family: Arial !important;\n    font-weight: 500 !important;\n}\n\n.parraf {\n    font-family: Arial;\n}\n\n.cardTitle {\n    font-family: ITC Avant Garde Std !important;\n    font-weight: bold !important;\n}\n\n.iconContainer {\n    background-color: #cfdb00 !important;\n}\n\n.icon {\n    font-size: 20px; \n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `dataScienceService_title__SXq45`,
	"subtitle": `dataScienceService_subtitle__THdQm`,
	"parraf": `dataScienceService_parraf__u8YBb`,
	"cardTitle": `dataScienceService_cardTitle__iwvPs`,
	"iconContainer": `dataScienceService_iconContainer__Wadf4`,
	"icon": `dataScienceService_icon__Fn+Vc`
};
export default ___CSS_LOADER_EXPORT___;
