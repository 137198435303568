import style from '../../styles/desarrolloService.module.css'
import openWhatsApp from '../../utils/openWhatsApp';

function DesarrolloService () {
    return ( 
        <div className="container px-4 py-5">
        <h2 className={`pb-2 border-bottom ${style.title}`} data-aos="fade-right">Desarrollamos Software a tu medida</h2>
    
        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
          <div className="col d-flex flex-column align-items-start gap-2" data-aos="fade-right">
            <h2 className={`fw-bold text-body-emphasis ${style.secondTitle}`}>Nos especializamos en desarrollo de software web  para empresas de todos los
            tamaños.</h2>
            <p className={`text-body-secondary ${style.subtitle}`}>Despliegue continuo (CI/CD) y Integración de sistemas y APIs</p>
            {/* <BtnContact/> */}
          </div>
    
          <div className="col">
            <div className="row row-cols-1 row-cols-sm-2 g-4">
              <div onClick={openWhatsApp} className={`col d-flex flex-column gap-2 ${style.card}`} data-aos="fade-right">
                <div className={`feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3 ${style.icon}`}>
                  <i className="bi bi-code-slash"></i>
                </div>
                <h4 className={`fw-semibold mb-0 text-body-emphasis ${style.cardTitle}`}>Diseño y desarrollo de aplicaciones web personalizadas</h4>
                <p className="text-body-secondary">Desarrollo Responsive con soporte y mantenimiento</p>
              </div>
    
              <div onClick={openWhatsApp} className={`col d-flex flex-column gap-2 ${style.card}`} data-aos="fade-right">
                <div className={`feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3 ${style.icon}`}>
                  <i className="bi bi-bag-check"></i>
                </div>
                <h4 className={`fw-semibold mb-0 text-body-emphasis ${style.cardTitle}`}>Desarrollo de plataformas de comercio electrónico</h4>
                <p className="text-body-secondary">Crea tu propio E-Commerce</p>
              </div>
    
              <div onClick={openWhatsApp} className={`col d-flex flex-column gap-2 ${style.card}`} data-aos="fade-right">
                <div className={`feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3 ${style.icon}`}>
                  <i className="bi bi-fingerprint"></i>
                </div>
                <h4 className={`fw-semibold mb-0 text-body-emphasis ${style.cardTitle}`}>Seguridad y cumplimiento normativo</h4>
                <p className="text-body-secondary">Cumplimiento de buenas practicas.</p>
              </div>
    
              <div onClick={openWhatsApp} className={`col d-flex flex-column gap-2 ${style.card}`} data-aos="fade-right">
                <div className={`feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3 ${style.icon}`}>
                  <i className="bi bi-bar-chart-steps"></i>
                </div>
                <h4 className={`fw-semibold mb-0 text-body-emphasis ${style.cardTitle}`}>Desarrollo impulsado por pruebas (TDD)</h4>
                <p className="text-body-secondary">Asesoría y acompañamiento en tu proyectos</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )

}

export default DesarrolloService;