const openWhatsApp = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const phoneNumber = process.env.REACT_APP_TELEFONO; // Reemplaza con el número de teléfono que quieres usar
    const message = "Hola, me gustaría obtener más información."; // Reemplaza con tu mensaje predeterminado
  
    if (mobile) {
      window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    } else {
      window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      // window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
    }
};

export default openWhatsApp;