import style from '../../styles/dataScienceService.module.css'

function DataScienceService () {
    return (
        <div className="container px-4 py-5" id="hanging-icons">
        <h2 className={`pb-2 ${style.title}`}>CIENCIA DE DATOS</h2>
        <h4 className={`pb-2 border-bottom ${style.subtitle}`}>Integración de ciencia de datos en el modelado de datos</h4>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          <div className="col d-flex align-items-start" data-aos="fade-right" data-aos-delay="500">
            <div className={`${style.iconContainer} icon-square text-body-emphasis bg-primary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 rounded-3`}>
              <i className={`bi bi-cloud-fill m-1 px-1 ${style.icon}`}></i>
            </div>
            <div>
              <h3 className={`fs-2 text-body-emphasis ${style.cardTitle}`}>Diseño de bases de datos optimizadas</h3>
              <p className={style.parraf}>Ofrecemos soluciones de bases de datos de alta calidad, garantizando eficiencia y rendimiento superior. Confía en nuestros expertos para una organización óptima de tus datos, asegurando acceso rápido y seguro.</p>
              {/* <BtnContact mensaje="Solicita la tuya"/>   */}
            </div>
          </div>
          <div className="col d-flex align-items-start" data-aos="fade-up" data-aos-delay="200">
            <div className={`${style.iconContainer} icon-square text-body-emphasis bg-primary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 rounded-3`}>
              <i className={`bi bi-cloud-fill m-1 px-1 ${style.icon}`}></i>
            </div>
            <div>
              <h3 className={`fs-2 text-body-emphasis ${style.cardTitle}`}>Desarrollo de modelos de datos para análisis predictivo</h3>
              <p className={style.parraf}>Creamos modelos de datos avanzados para ofrecer análisis predictivos precisos y útiles. Nuestro enfoque garantiza que puedas anticipar tendencias y tomar decisiones informadas para impulsar el crecimiento de tu negocio.</p>
              {/* <BtnContact/>   */}
            </div>
          </div>
          <div className="col d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
            <div className={`${style.iconContainer} icon-square text-body-emphasis bg-primary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 rounded-3`}>
              <i className={`bi bi-cloud-fill m-1 px-1 ${style.icon}`}></i>
            </div>
            <div>
              <h3 className={`fs-2 text-body-emphasis ${style.cardTitle}`}>Implementación de soluciones de Big Data</h3>
              <p className={style.parraf}>Implementamos soluciones de Big Data que transforman grandes volúmenes de datos en información valiosa. Nuestra experiencia garantiza una gestión eficiente y análisis profundo, impulsando decisiones estratégicas para tu negocio.</p>
              {/* <BtnContact mensaje="Implementalo"/>   */}
            </div>
          </div>
        </div>
      </div>
    );
}


export default DataScienceService;