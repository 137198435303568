import Container from 'react-bootstrap/Container';
import style from '../../styles/Services.module.css'
import ServicesGroup from './ServicesGroup'

function Services() {
  return (
    <Container fluid className={`pb-5 ${style.services}`}>
        <h1 className={`text-center ${style.title}`}>Nuestros <span className={style.span}>Servicios</span></h1>
        <hr className="my-4 mx-auto" style={{ width: '50%' }} ></hr>
        <h3 className={`text-center mb-2 mb-md-5 ${style.subtitle}`}>En DIRTEK nos especializamos en proporcionar <br></br> soluciones integrales de tecnología de la información</h3>

        <ServicesGroup></ServicesGroup>

        <div className='mb-3 mb-md-5'></div>

        {/* <BtnContact></BtnContact> */}

    </Container>
  );
}

export default Services;