// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consultoriaService_title__-ZXQ\\+ {
    font-family: ITC Avant Garde Std;
    font-weight: bold;
}

.consultoriaService_text__p\\+Sf1 {
    font-family: ITC Avant Garde Std;
    font-weight: 400;
    
}

.consultoriaService_boton__tsLkL {
    background-image: linear-gradient(#cfdb00 40%, #d0db005a 100%);
    font-family: ITC Avant Garde Std;
    
}`, "",{"version":3,"sources":["webpack://./src/styles/consultoriaService.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;;AAEpB;;AAEA;IACI,8DAA8D;IAC9D,gCAAgC;;AAEpC","sourcesContent":[".title {\n    font-family: ITC Avant Garde Std;\n    font-weight: bold;\n}\n\n.text {\n    font-family: ITC Avant Garde Std;\n    font-weight: 400;\n    \n}\n\n.boton {\n    background-image: linear-gradient(#cfdb00 40%, #d0db005a 100%);\n    font-family: ITC Avant Garde Std;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `consultoriaService_title__-ZXQ+`,
	"text": `consultoriaService_text__p+Sf1`,
	"boton": `consultoriaService_boton__tsLkL`
};
export default ___CSS_LOADER_EXPORT___;
