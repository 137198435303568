import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import art1 from '../../assets/svg/Innovation-bro.svg'
import style from '../../styles/ExperienceSection.module.css'

const ExperienceSection = () => {
  return (
    <Container fluid className={style.about}>
        <Container>
            <Row>
                <Col
                xs={12}
                md={6}
                className="d-flex justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-duration="500"
                >
                <img
                    src={art1}
                    alt="Us"
                    className={`img-fluid p-md-5 ${style.image}`}
                />
                </Col>
                <Col
                xs={12}
                md={6}
                className="d-flex justify-content-start align-items-top"
                data-aos="fade-up"
                data-aos-duration="500"
                >
                <div className="w-100 p-md-5" style={{height: "100%"}}>
                    <h4 className={`mb-3 mt-5 ${style.subtitle}`}>"El mejor momento para empezar fue ayer, el segundo mejor es ahora."</h4>
                    <h6 className={`mb-3 mt-md-5 mt-1 ${style.parraf}`}>Además, incorporamos técnicas avanzadas de <span className={style.span}>machine learning</span> y <span className={style.span}>análisis predictivo</span> en nuestros modelos de datos, permitiendo a nuestros clientes obtener insights valiosos y tomar decisiones estratégicas basadas en datos con confianza.

                    </h6>
                </div>
                </Col>
            </Row>
        </Container>
    </Container>
  );
};

export default ExperienceSection;