import React, { useState } from 'react';

const logos = {
    logo01: require('../assets/svg/logos/Logo01.svg').default,
    logo02: require('../assets/svg/logos/Logo02.svg').default,
    logo03: require('../assets/svg/logos/Logo03.svg').default,
    logo04: require('../assets/svg/logos/Logo04.svg').default,
    logo05: require('../assets/svg/logos/Logo05.svg').default,
  };

const LogoComponent = ({ logoName, width }) => {
    const [isHovered, setIsHovered] = useState(false);

    const initialLogoSrc = logos[logoName] || logos.logo01; // Fallback al primer logo si no se encuentra el nombre
    const hoverLogoSrc = logos.logo02; // Cambiar a cualquier otro logo deseado al pasar el mouse

    const logoSrc = isHovered ? hoverLogoSrc : initialLogoSrc;

    let x = 85 / 11

    // Razon de proporcionalida + Validacion de que el ancho sea mayor a 85px
    if( width > 85) {
        x = width / 11
    }

    const height = 3 * x
    const marginVertical = 2 * x
    const marginHorizontal = 2 * x
  
  const logoStyle = {
    width: isHovered ? width * 1.2 : width, // Aumentar el ancho en un 20% al pasar el mouse
    height: isHovered ? height * 1.2 : height,
    marginTop: marginVertical,
    marginBottom: marginVertical,
    marginLeft: marginHorizontal,
    marginRight: marginHorizontal,
    transition: 'all 0.3s ease-in-out', // Añadir una transición suave
  };

  
  return (
    <img 
        src={logoSrc} 
        alt="Logo" 
        style={logoStyle} 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    />
);
  };


export default LogoComponent;
