import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './components/navbar'
import HomePage from './pages/HomePage';
import ServicesPages from './pages/ServicesPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import AboutUsPage from './pages/AboutUsPage';
import FloatingButton from './components/FloatingButton';
import NotFoundPage from './pages/NotFoundPage'
import PoliticasPage from './pages/PoliticasPage';
import './styles/styles.css'
import Footer from './components/Footer';


function Page () {
  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Navbar/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPages />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/privacy" element={<PoliticasPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      <Footer/>
      <FloatingButton/>
    </>
  )
}

function App () {
  return (
    <ThemeProvider
    breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    minBreakpoint="xxs"
    >
      <Router>
        <Page/>
      </Router>
    </ThemeProvider>
  )
}


export default App