import art1 from '../../assets/svg/Live collaboration-pana.svg'
import style from '../../styles/consultoriaService.module.css'


function ConsultoriaService () {

    return (

        <>

        <div className="container col-xxl-8 px-4 py-5" data-aos="fade-right" data-aos-duration="500">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-10 col-sm-8 col-lg-6">
                    <img src={art1} className="d-block mx-lg-auto img-fluid" alt="Liver colaboration" loading="lazy"></img>
                </div>
                <div className="col-lg-6">
                    <h1 className={`display-5 fw-bold text-body-emphasis lh-1 mb-3 ${style.title}`}>CONSULTORÍA TECNOLÓGICA</h1>
                    <p className={`lead ${style.text}`}>Ofrecemos servicios de consultoría tecnológica para llevar tu negocio al siguiente nivel. Nos especializamos en evaluación de infraestructuras tecnológicas, asesoramiento en transformación digital, y optimización de procesos mediante tecnología avanzada.

Utilizamos análisis de datos y Business Intelligence (BI) para transformar datos en decisiones estratégicas, asegurando la gestión de riesgos y cumplimiento normativo.

Con nuestra experiencia y experticia, brindamos un enfoque personalizado y un soporte integral que impulsa el crecimiento y éxito de tu negocio.</p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    {/* <button type="button" onClick={openWhatsApp} className={`btn btn-lg px-4 me-md-2 ${style.boton}`}>Contáctenos</button> */}
                    </div>
                </div>
            </div>
        </div>


        </>

    )

}

export default ConsultoriaService;