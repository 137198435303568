import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import art1 from '../../assets/svg/ServiceSvgs/business-analytics-not-css.svg'
import style from '../../styles/About.module.css'

const About = () => {
  return (
    <Container fluid className={style.about}>
        <Container >
            <Row className="">
                <Col
                xs={12}
                md={6}
                className="d-flex justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
                >
                <img
                    src={art1}
                    alt="example"
                    className={`img-fluid ${style.image}`}
                />
                </Col>
                <Col
                xs={12}
                md={6}
                className="d-flex justify-content-start align-items-top"
                data-aos="fade-up"
                data-aos-duration="1000"

                >
                <div className="w-100">
                    <h4 className={`mb-3 mt-5 ${style.subtitle}`}>Impulsamos el crecimiento empresarial</h4>
                    <h1 className={`mb-3 mt-2 ${style.title}`}>Soluciones Integrales en Tecnología de la Información</h1>
                    <h6 className={`mb-3 mt-md-5 mt-1 ${style.parraf}`}>En <span className={style.span}>DIRTEK</span>, nos especializamos en desarrollar software web y modelos de datos personalizados para empresas de todos los tamaños. Nuestro equipo multidisciplinario, compuesto por expertos en programación, arquitectura de software y análisis de datos, está dedicado a ofrecer soluciones innovadoras que impulsen el éxito empresarial.
            
                    </h6>
                </div>
                </Col>
            </Row>
        </Container>
    </Container>
  );
};

export default About;