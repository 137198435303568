import React, { useState,useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import style from '../styles/Contact.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import emailjs from 'emailjs-com';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import { LinkContainer } from 'react-router-bootstrap';


function Contact () {


    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });

    const [isSending, setIsSending] = useState(false);
    const [lastSentTime, setLastSentTime] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState('');


    useEffect(() => {
        // Configura EmailJS con tu clave pública
        emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {

        e.preventDefault();

          // Validar campos
        if (!formData.name || !formData.phone || !formData.email || !formData.message) {
            setStatusMessage('Campo requerido')
            setStatusType('warning');
            return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            setStatusMessage('Introduzca una dirección de correo electrónico válida.')
            setStatusType('warning');
            return;
        }

        const currentTime = new Date().getTime();
        if (lastSentTime && currentTime - lastSentTime < 60000) { // 60000ms = 1 minute
            setStatusMessage('Espere un minuto antes de enviar otro correo.');
            setStatusType('warning');
            return;
        }


        setIsSending(true);
        setStatusMessage('');
        setStatusType('');

        const templateParams = {
          from_name: formData.name,
          from_phone: formData.phone,
          user_email: formData.email,
          message: formData.message,
          to_email: process.env.REACT_APP_CORREO
        };
    
        emailjs
          .send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_USER_ID
          )
          .then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
              setStatusMessage('Mensaje enviado. Pronto le responderemos.');
              setStatusType('success'); // Set message type
              setFormData({ name: '', phone: '', email: '', message: '' });
              setLastSentTime(currentTime);
              setIsSending(false);
            },
            (error) => {
              console.log('FAILED...', error);
              setStatusMessage('Failed to send the message. Please try again.');
              setStatusType('error'); // Set message type
              setIsSending(false);
            }
          );
      };
    

    return (
        <section className={style.contact} data-aos="flip-left" data-aos-duration="1000" id='contact'>
                <Container className={style.container}>
                <Row className={style.rowFixed}>

                    <div className={`col-md-7 ${style.colFixed}`}>
                        <div className={style.heading}>Contáctenos</div>
                        <p className={style.parraf}>En <span className={style.span}>DIRTEK</span> nos comprometemos a ser tu socio confiable en el viaje hacia el éxito tecnológico.</p>
                        <form onSubmit={handleSubmit}>
                            <i className="bi bi-person-fill me-2"></i>
                            <input 
                                className={`form-control ${style.input}`} 
                                type="text" 
                                placeholder="Tu nombre" 
                                name="name" 
                                value={formData.name}
                                onChange={handleChange}
                                required
                                autoComplete="on"
                            >
                            </input><br></br>
                            <i className="bi bi-envelope-fill me-2"></i>
                            <input 
                                className={`form-control ${style.input}`} 
                                type="email" 
                                placeholder="Tu correo electrónico" 
                                name="email" 
                                value={formData.email}
                                onChange={handleChange}
                                required
                                autoComplete="on"

                                >
                            </input><br></br>
                            <i className="bi bi-phone-fill me-2"></i>
                            <input 
                                className={`form-control ${style.input}`} 
                                placeholder="Tu telefono"
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                autoComplete="on"
                            >
                                    
                                    
                            </input><br></br>
                            <div className={style.mensajeArea}>
                                <textarea 
                                    className={`form-control ${style.mensaje}`} 
                                    id="message" 
                                    name="message" 
                                    rows="4" 
                                    cols="50" 
                                    placeholder="Escribe tu mensaje aquí..." 
                                    required
                                    value={formData.message}
                                    onChange={handleChange}
                                >
                            </textarea>
                            </div>
                            <button className={style.contactBtn} type="submit" disabled={isSending}>Mandar Mensaje</button>
                        </form>
                        {statusMessage && <div className={`${style.statusMessage} ${style[statusType]}`}>{statusMessage}</div>}
                    </div>

                    <div className={`col-md-5 ${style.colFixed2}`}>
                        <h1 className={style.heading2}>Info</h1>
                        <p className={style.parraf2}><i className="bi bi-envelope-fill">{` ${process.env.REACT_APP_CORREO}`}</i></p>
                        <p className={style.parraf2}><i className="bi bi-phone-fill">{` ${formatPhoneNumber(process.env.REACT_APP_TELEFONO)}`}</i></p>
                        <p className={style.parraf2}><i className="bi bi-building"> Colombia</i></p>
                        <p className={style.parraf2}>Confía en nosotros para ofrecerte soluciones tecnológicas de la más alta calidad que impulsen el crecimiento y la eficiencia de tu empresa.</p>
                        <LinkContainer to="/privacy" className='ps-3'>
                            <p className={style.amazingLink}>Políticas y privacidad</p>
                        </LinkContainer>

                    </div>
                </Row>
            </Container>
        </section>
    );

}

export default Contact;