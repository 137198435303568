// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FloatingButton_boton__Xr192 {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: block; /* Mostrar el botón cuando la pantalla es menor a 768px */
    
}

.FloatingButton_boton__Xr192 {
    border-radius: 10px !important;
    padding: 10px;
    background-image: linear-gradient(to right, #cfdb00, #E7EC9E) !important;
    border: none;
    font-family: ITC Avant Garde Std;
    font-weight: bolder;
    font-size: 20px ;
    color: black;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.FloatingButton_wpBoton__UHYsO {
    width: 30px;
    height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/FloatingButton.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,cAAc,EAAE,yDAAyD;;AAE7E;;AAEA;IACI,8BAA8B;IAC9B,aAAa;IACb,wEAAwE;IACxE,YAAY;IACZ,gCAAgC;IAChC,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,yCAAyC;AAC7C;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".boton {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    display: block; /* Mostrar el botón cuando la pantalla es menor a 768px */\n    \n}\n\n.boton {\n    border-radius: 10px !important;\n    padding: 10px;\n    background-image: linear-gradient(to right, #cfdb00, #E7EC9E) !important;\n    border: none;\n    font-family: ITC Avant Garde Std;\n    font-weight: bolder;\n    font-size: 20px ;\n    color: black;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n.wpBoton {\n    width: 30px;\n    height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boton": `FloatingButton_boton__Xr192`,
	"wpBoton": `FloatingButton_wpBoton__UHYsO`
};
export default ___CSS_LOADER_EXPORT___;
