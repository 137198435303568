// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.space_space__n2tST {
    padding: 0 !important;
    background-color: #bacdcf;
    position: relative;
    margin-top: -5px;
    margin-bottom: -5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/space.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".space {\n    padding: 0 !important;\n    background-color: #bacdcf;\n    position: relative;\n    margin-top: -5px;\n    margin-bottom: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": `space_space__n2tST`
};
export default ___CSS_LOADER_EXPORT___;
