// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crmService_title__2KUVG {
    font-family: ITC Avant Garde Std;
    font-weight: bold;
}

.crmService_cardTitle__KGBKp {
    font-family: Arial !important;
    font-weight: 600 !important;
    font-size: 25px !important;
}

.crmService_cardText__cXkof {

    font-family: Arial !important;

}

.crmService_icon__LOG0A {
    color: #cfdb00 !important;
    font-size: 20px;
}

.crmService_span__pFxn4 {
    color: #cfdb00;
}

.crmService_cardContainer__xgluz{
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.crmService_cardContainer__xgluz:hover {
    background-color: #CDDDE3;
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    
}`, "",{"version":3,"sources":["webpack://./src/styles/crmService.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;;IAEI,6BAA6B;;AAEjC;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,qDAAqD;AACzD;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,wCAAwC;;AAE5C","sourcesContent":[".title {\n    font-family: ITC Avant Garde Std;\n    font-weight: bold;\n}\n\n.cardTitle {\n    font-family: Arial !important;\n    font-weight: 600 !important;\n    font-size: 25px !important;\n}\n\n.cardText {\n\n    font-family: Arial !important;\n\n}\n\n.icon {\n    color: #cfdb00 !important;\n    font-size: 20px;\n}\n\n.span {\n    color: #cfdb00;\n}\n\n.cardContainer{\n    border-radius: 5px;\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n.cardContainer:hover {\n    background-color: #CDDDE3;\n    transform: scale(1.05); \n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `crmService_title__2KUVG`,
	"cardTitle": `crmService_cardTitle__KGBKp`,
	"cardText": `crmService_cardText__cXkof`,
	"icon": `crmService_icon__LOG0A`,
	"span": `crmService_span__pFxn4`,
	"cardContainer": `crmService_cardContainer__xgluz`
};
export default ___CSS_LOADER_EXPORT___;
