// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desarrolloService_title__ER5-w {
    font-family: ITC Avant Garde Std !important;
    font-weight: bold !important;
}

.desarrolloService_secondTitle__Ffc7e {
    font-family: Arial !important;
    font-weight: 500 !important;
}

.desarrolloService_subtitle__p93iU {
    font-family: Arial !important;
    font-weight: bold;
}

.desarrolloService_icon__qkA9O {
    background-color:  #00567d !important;
    width: 40px;
}

.desarrolloService_cardTitle__DJjAm {
    font-family: ITC Avant Garde Std !important;
    font-weight: bold !important;
}

.desarrolloService_card__lgmOY {
    border-radius: 5px;
    padding-top: 5px;
}

.desarrolloService_card__lgmOY:hover {
    background-color: #CDDDE3;
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}`, "",{"version":3,"sources":["webpack://./src/styles/desarrolloService.module.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;IAC7B,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,qCAAqC;IACrC,WAAW;AACf;;AAEA;IACI,2CAA2C;IAC3C,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,wCAAwC;;AAE5C","sourcesContent":[".title {\n    font-family: ITC Avant Garde Std !important;\n    font-weight: bold !important;\n}\n\n.secondTitle {\n    font-family: Arial !important;\n    font-weight: 500 !important;\n}\n\n.subtitle {\n    font-family: Arial !important;\n    font-weight: bold;\n}\n\n.icon {\n    background-color:  #00567d !important;\n    width: 40px;\n}\n\n.cardTitle {\n    font-family: ITC Avant Garde Std !important;\n    font-weight: bold !important;\n}\n\n.card {\n    border-radius: 5px;\n    padding-top: 5px;\n}\n\n.card:hover {\n    background-color: #CDDDE3;\n    transform: scale(1.05); \n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `desarrolloService_title__ER5-w`,
	"secondTitle": `desarrolloService_secondTitle__Ffc7e`,
	"subtitle": `desarrolloService_subtitle__p93iU`,
	"icon": `desarrolloService_icon__qkA9O`,
	"cardTitle": `desarrolloService_cardTitle__DJjAm`,
	"card": `desarrolloService_card__lgmOY`
};
export default ___CSS_LOADER_EXPORT___;
