import style from '../styles/FloatingButton.module.css'
import { Button } from 'react-bootstrap';
import wpIcon from '../assets/svg/Digital_Glyph_White.svg'
import openWhatsApp from '../utils/openWhatsApp';


function FloatingButton () {

    return (
        <Button className={style.boton} onClick={openWhatsApp}><img src={wpIcon} className={style.wpBoton} alt="iconoWhatsApp"></img></Button>
    )

}

export default FloatingButton;