import { Container, Image } from 'react-bootstrap';
import style from '../styles/NotFoundPage.module.css'
import art from '../assets/svg/404 error lost in space-amico.svg'
import { LinkContainer } from 'react-router-bootstrap';

function NotFoundPage () {
    return (
        <Container className={style.container}>
            <Image src={art} className={style.image}></Image>
            <LinkContainer className={`ms-4 me-2 ${style.buttoncontainer}`} to="/">
                <a className={`p-2 px-3 ${style.amazingbutton}`} href="#contact">Volver al Menú</a>
            </LinkContainer>
        </Container>
    );
}

export default NotFoundPage;