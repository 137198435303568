import QRCode from 'qrcode';

const generateQR = async (phoneNumber) => {
  try {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    const qrCode = await QRCode.toDataURL(url); // Genera una cadena Base64
    return qrCode;
  } catch (error) {
    console.error("Error generating QR code:", error);
    return null;
  }
};

export default generateQR;