import { Container } from "react-bootstrap";
import AboutUs from "../components/about/AboutUs";
import ExperienceSection from "../components/about/ExperienceSection";
import Contact from '../components/Contact'
import style from '../styles/AboutUsPage.module.css'

function AboutUsPage () {

    return (
        <Container fluid className={style.section}>
            <AboutUs/>
            <ExperienceSection/>
            <Contact/>
        </Container>
    )


}

export default AboutUsPage;