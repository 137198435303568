import style from '../styles/PoliticasPage.module.css'


function PoliticasPage() {
    return (
        <div className={style.privacyContainer}>
            <h1 className={style.heading1}>Política de Privacidad para el Manejo de Listas de Correo</h1>

            <h2 className={style.heading2}>1. Introducción</h2>
            <p className={style.parraf}>En DIRTEK, nos comprometemos a proteger la privacidad de nuestros usuarios y clientes. Esta Política de Privacidad describe cómo recopilamos, utilizamos, y protegemos la información personal que recibimos de las listas de correo que gestionamos. Al suscribirse a nuestras listas de correo, aceptas las prácticas descritas en esta política.</p>

            <h2 className={style.heading2}>2. Recopilación de Información</h2>
            <h3 className={style.heading3}>2.1. Información Personal Recopilada:</h3>
            <p className={style.parraf}>Recopilamos la siguiente información personal cuando los usuarios se suscriben a nuestras listas de correo:</p>
            <ul className={style.unorderList}>
                <li>Nombre completo</li>
                <li>Dirección de correo electrónico</li>
                <li>Información adicional proporcionada voluntariamente, como preferencias de comunicación o intereses.</li>
            </ul>
            <h3 className={style.heading3}>2.2. Fuentes de Información:</h3>
            <p className={style.parraf}>La información puede ser recopilada directamente de los usuarios a través de formularios de suscripción en nuestro sitio web, eventos, u otras interacciones donde se solicita la suscripción a nuestras comunicaciones.</p>

            <h2 className={style.heading2}>3. Uso de la Información</h2>
            <h3 className={style.heading3}>3.1. Propósitos del Uso:</h3>
            <p className={style.parraf}>Utilizamos la información personal recopilada para:</p>
            <ul className={style.unorderList}>
                <li>Enviar boletines, promociones, y otras comunicaciones relacionadas con nuestros productos, servicios, y eventos.</li>
                <li>Mejorar la personalización de nuestras comunicaciones en función de los intereses y preferencias de los usuarios.</li>
                <li>Cumplir con obligaciones legales y normativas.</li>
            </ul>
            <h3 className={style.heading3}>3.2. Compartición de Información:</h3>
            <p className={style.parraf}>No compartimos, vendemos, ni alquilamos la información personal de nuestros suscriptores con terceros no relacionados, salvo en los siguientes casos:</p>
            <ul className={style.unorderList}>
                <li>Proveedores de servicios que nos asisten en la gestión y envío de correos electrónicos y que están sujetos a obligaciones de confidencialidad.</li>
                <li>Cumplimiento de la ley, como respuesta a solicitudes legales o para proteger nuestros derechos.</li>
            </ul>

            <h2 className={style.heading2}>4. Protección de la Información</h2>
            <h3 className={style.heading3}>4.1. Medidas de Seguridad:</h3>
            <p className={style.parraf}>Implementamos medidas de seguridad técnicas, administrativas y físicas para proteger la información personal contra el acceso no autorizado, alteración, divulgación o destrucción.</p>
            <h3 className={style.heading3}>4.2. Conservación de Datos:</h3>
            <p className={style.parraf}>Conservamos la información personal solo durante el tiempo necesario para cumplir con los fines para los que fue recopilada, o según lo requerido por la ley aplicable.</p>

            <h2 className={style.heading2}>5. Derechos de los Usuarios</h2>
            <h3 className={style.heading3}>5.1. Acceso y Rectificación:</h3>
            <p className={style.parraf}>Los suscriptores tienen derecho a acceder, corregir o actualizar su información personal en cualquier momento. Para ello, pueden contactarnos a través de los medios proporcionados al final de esta política.</p>
            <h3 className={style.heading3}>5.2. Cancelación de Suscripción:</h3>
            <p className={style.parraf}>Los usuarios pueden optar por no recibir más comunicaciones nuestras en cualquier momento haciendo clic en el enlace de cancelación de suscripción que se incluye en todos nuestros correos electrónicos o contactándonos directamente.</p>

            <h2 className={style.heading2}>6. Cambios en la Política de Privacidad</h2>
            <p className={style.parraf}>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será publicado en esta página y, en la medida en que sea posible, se notificará a los suscriptores por correo electrónico.</p>

            <h2 className={style.heading2}>7. Contacto</h2>
            <p className={style.parraf}>Si tienes preguntas o inquietudes sobre esta Política de Privacidad o sobre nuestras prácticas de manejo de la información, puedes contactarnos en:</p>
            <ul className={style.unorderList}>
                <li>Email: <a className={style.amazingLink} href="mailto:dirtek.med@gmail.com">dirtek.med@gmail.com</a></li>
                <li>Teléfono: +57 3005414728</li>
            </ul>
        </div>
    );
}

export default PoliticasPage;