import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import LogoComponent from './LogoComponent';
import styles from '../styles/navbar.module.css';
import { useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';


function Header() {
  const [navbarShadow, setNavbarShadow] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarShadow(true);
    } else {
      setNavbarShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <Navbar expand="md" className={`${styles.navbar} ${navbarShadow ? styles.navbarShadow : ''}`} sticky="top">
      <Container fluid="lg">
        <LinkContainer to="/">
          <Navbar.Brand className={styles.navBarBrand}>
            <LogoComponent width={150} logoName="logo01"/>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer to="/">
              <Nav.Link className={`mx-lg-3 ${styles.amazinglink} ${styles.navLinks} ${location.pathname === '/' ? styles.active : ''}`}>
                Menú
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/services">
              <Nav.Link className={`mx-lg-3 ${styles.amazinglink} ${styles.navLinks} ${location.pathname === '/services' ? styles.active : ''}`}>
                Servicios
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link className={`mx-lg-3 ${styles.amazinglink} ${styles.navLinks} ${location.pathname === '/about' ? styles.active : ''}`}>
                Quienes Somos
              </Nav.Link>
            </LinkContainer>
            <div className={`ms-4 me-2 ${styles.buttoncontainer}`}>
              <ScrollLink to="contact" smooth={true} duration={50} className={`p-2 px-3 ${styles.amazingbutton}`} href="#contact">
                Contáctenos
              </ScrollLink>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;