import CrmService from "../components/services/CrmService";
import DesarrolloService from "../components/services/DessarrolloService";
import Contact from '../components/Contact'
import DataScienceService from "../components/services/DataScienceService";
import ConsultoriaService from "../components/services/ConsultoriaService";
import { Container } from "react-bootstrap";

function ServicesPage () {

  const backgroundStyle = {
    margin: 0,
    padding: 0,
    background: "url('../assets/img/bcTexture2.png') no-repeat center center fixed",
    backgroundSize: 'cover',
  }

    return ( 

      <Container fluid style={backgroundStyle}>
        <DesarrolloService/>
        <ConsultoriaService/> 
        <DataScienceService/>
        <CrmService/>
        <Contact/>
      </Container>


    )

}

export default ServicesPage;