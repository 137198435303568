import Container from "react-bootstrap/Container";
import style from '../../styles/space.module.css'

function Space ({altura}) {
    return (
        <Container fluid className={style.space} style={{height: `${altura}px`}} id="about">
        </Container>
    )
}

export default Space;