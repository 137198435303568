import LogoComponent from "./LogoComponent";
import style from '../styles/Footer.module.css'

function Footer () {
    return (
        <footer className={`pt-5 d-none d-md-block ${style.footer}`}>
            <LogoComponent logoName="logo04" width={75}></LogoComponent>
            <div className="social-links text-center">
                <i className="fa-brands fa-twitter"></i>
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-instagram"></i>
                <i className="fa-brands fa-youtube"></i>
                <i className="fa-brands fa-pinterest"></i>
            </div>

            <div className="copyright text-center">
                &copy; Copyright <strong><span>DIRTEK</span></strong>.All Rights reserved
            </div>

        </footer>
    )
}

export default Footer;